.border-gray {
    border-radius: 10px !important;
    border: #B9B9B9 1px solid !important;

}

.bolder {
    font-weight: bolder !important;
}

.marginb-5 {
    margin-bottom: 5rem !important;
}