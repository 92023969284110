/* .nav-link {
    width: fit-content;
} */

.nav-link .active {
    width: 10vw;
}

.tab-content {
    /* max-height: 450px; */
    overflow-y: scroll;
}

@media (min-width: 320px) and (max-width: 780px) {

    .nav-link {
        width: fit-content !important;
    }

    .nav-link .active {
        width: fit-content;
    }

}
.nav-link, .nav-tabs .nav-link:hover {
    min-width: 15vw;
    width: max-content;
    max-width: max-content;
}

.product-container .col{
    width: 30vw !important;
    max-width: 50% !important; 
}

.product-tab{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100%;
}