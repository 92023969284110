.warehouse {
    display: inline-block !important;
    /* width: fit-content !important; */
    font-family: "Gotham-Rounded-Medium";
    /* line-height: 16px; */
    border-radius: 20px !important;
    max-height: 35px !important;
    background-color: white !important;
    box-shadow: none !important;
    border: 1px solid #59c8e3 !important;
    /* padding: 1vh 0.5vw !important; */
    /* background-size: 7% !important; */
    color: #59c8e3 !important;
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e) !important;
}

.font-medium{
    font-family: "Gotham-Rounded-Medium";
}

.p-sides-10{
    padding-left: 10px;
    padding-right: 10px;
}

.br-10{
    border-radius: 10px;
}

.dispatcher{
    font-family: 'Gotham-Rounded-Medium';
    font-style: normal;
    color: #545454 !important;
    border-radius: 10px !important;
}

.w-15 {
    width: 15%;
}

.w-300 {
    width: 300px !important;
}

.mr-3 {
    margin-right: 3px;
}


.manager-container{
    margin-left: 8%;
    margin-right:  3%;
    margin-top: 2%;
    transition: magin-left .2s ease-in;
    box-sizing: border-box;
}

.download-csv {
    text-decoration: none !important;
}

.blue {
    color: rgb(42, 42, 242)
}

.front {
    z-index: 10;
}

.req-table-w-border-bottom {
    border-bottom: 1px solid #B9B9B9 !important;
}