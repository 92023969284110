.validity-error {
	color: #dc3444;
	text-transform: capitalize;
	z-index: 1000 !important;
	font-size: 11px;
	/* margin-top: -2%;
	margin-bottom: 1%; */
}

div.callout {
	z-index: 1000 !important;
	font-size: x-small;
	/* background-color: #444; */
	background-color: #ffc107;
	background-image: -moz-linear-gradient(top, #ffc107, #ffc107);
	position: relative;
	color: #212529;
	width: fit-content;
	padding: 2px;
	border-radius: 3px;
	box-shadow: 0px 0px 20px #999;
	border: 1px solid #ffc107;
	/* text-shadow: 0 0 1px #000; */

	/*box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset;*/
}

.callout::before {
	content: "";
	width: 0px;
	height: 0px;
	border: 0.8em solid transparent;
	position: absolute;
	z-index: 1000 !important;
}

.callout.bottom::before {
	left: 45%;
	top: -19px;
	border-bottom: 10px solid #ffc107;
	z-index: 1000 !important;
}