.rdt_TableHeadRow {
    font-family: "Gotham-Rounded-Bold";
    font-size: 12px !important;
    line-height: 14px !important;
    color: #eee;
    text-transform: uppercase;
    background: #59c8e3 !important;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25) !important;
    border-radius: 5px;
    max-width: 100% !important;
    width: 98%;
    min-height: 7vh !important;
    height: 7vh !important;
    max-height: 30vh;
    flex: 1;
    justify-content: space-between;
    word-wrap: break-word;
    white-space: unset;
    z-index: 2;
}
  
.rdt_TableHead {
    z-index: 1 !important;
}

.rdt_TableCol,
.rdt_TableCol_Sortable {
    height: 7vh;
    /* max-width: fit-content; */
    /* word-wrap: break-word; */
    word-wrap: break-word;
    /* white-space: normal; */
    white-space: unset;
    max-height: 10vw;
}

.rdt_TableRow {
    font-family: "Gotham-Rounded-Medium";
    font-size: 10px;
    line-height: 14px;
    color: black;
    text-transform: none;
    /* max-width: fit-content !important; */
    flex: 1;
    justify-content: space-between;
}


.rdt_Table {
    max-width: 98% !important;
    width: 95%;
    margin: 1vh 1vw 1vh 1vw;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    outline: none !important;
    flex-grow: inherit;
    white-space: unset;
    /* display: flex; */
    /* align-items: justify; */
  /* flex-wrap: nowrap; */
  /* align-items: stretch; */
}

/* .rdt_TableCol:last-child{
    max-width: 100% !important;
    margin-right: 1vw;
    text-align: left;
} */

.rdt_TableCell {
    /* min-width: 180px  !important; */
    width: 300px;
    /* max-width: max-content; */
    /* background: yellowgreen; */
    white-space: unset;
}

.jxflYm {
    font-family: "Gotham-Rounded-Medium" !important;
}

.rdt_Pagination {
    /* position: 4% 20% !important; */
    min-height: 6vh !important;
    height: 6vh !important;
}

.eIoOYs {
    max-height: 80vh !important;
    /* width: 98% !important; */
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    outline: none !important;
}