.add-type-btn {
    border: none;
    border-radius: 5px;
    background-color: #2a9d3a;
    color: #FFFFFF; 
}

.TabStyle1 {
    display: flex !important;
    flex-wrap: nowrap !important;
    align-items: stretch;
    margin: 0;
    padding: 0;
  }
  
.TabStyle2 {
    flex: 1;
    text-align: center;
  }